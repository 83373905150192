import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// import msal from 'vue-msal/lib/src/types';
import msal from 'vue-msal'
import '@/libs/acl'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins

import '@/libs/portal-vue'
import '@/libs/toastification'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import AsyncComputedPlugin from 'vue-async-computed'
import 'devextreme/dist/css/dx.light.css';
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(Toast);

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(AsyncComputedPlugin)

// Microsoft Auth
Vue.use(msal, {
  auth: {
    clientId: 'd9c26993-0a33-4526-9b8d-511cd985fec9'
  }
});

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
