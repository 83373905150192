import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'
import VueTheMask from 'vue-the-mask'

import 'vue-select/dist/vue-select.css';
import VueUpper from 'vue-upper';

Vue.use(VueUpper);

Vue.component('v-select', vSelect)

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.use(VueTheMask, {masked: false})


Vue.directive("disable-all", {
    // When all the children of the parent component have been updated
    componentUpdated: function (el, binding) {
        if ((!binding.hasOwnProperty('value')) || binding.value) {
            const tags = ["input", "button", "textarea", "select"];

            tags.forEach(tagName => {
                const nodes = el.getElementsByTagName(tagName);
                for (let i = 0; i < nodes.length; i++) {
                    nodes[i].disabled = true;
                    nodes[i].tabIndex = -1;
                }
            });
            const classNames = ["btn", "v-select", "vs__selected-options", "vs__dropdown-toggle", "vs--searchable"];
            classNames.forEach(className => {
                    const nodes = el.getElementsByClassName(className);
                    for (let i = 0; i < nodes.length; i++) {

                        //pointer-events:none;

                        nodes[i].style.pointerEvents = "none";
                        nodes[i].style.opacity = "0.7";
                        nodes[i].style.backgroundColor = "#e9ecef";

                    }
                }
            );
        }
    }
});

