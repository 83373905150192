import {RouteConfig} from "vue-router/types/router";

export default function ConfigureRoutes() {
    var routes: RouteConfig[] = [
        {
            path: '/proc-types',
            name: 'proc-types',
            component: () => import('@/views/procedures/ProcedureTypes.vue'),
            meta: {
                resource: 'ProcedureType',
                pageTitle: 'Procedure Types',
                breadcrumb: [
                    {
                        text: 'Procedure Types',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/proc-types/add-procedures',
            name: 'proc-types-add',
            component: () => import('@/views/procedures/AddProcedures.vue'),
            meta: {
                resource: 'ProcedureType',
                pageTitle: '',
                navActiveLink: 'proc-types',
                breadcrumb: [
                    {
                        text: 'Procedure Types',
                        active: false,
                        to: '/proc-types',
                    },
                    {
                        text: 'Add Procedure Type',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/proc-types/update-procedures/:id',
            name: 'proc-types-update',
            component: () => import('@/views/procedures/AddProcedures.vue'),
            meta: {
                resource: 'ProcedureType',
                pageTitle: '',
                navActiveLink: 'proc-types',
                breadcrumb: [
                    {
                        text: 'Procedure Types',
                        active: false,
                        to: '/proc-types',
                    },
                    {
                        text: 'Update Procedure Type',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/proc-codes',
            name: 'proc-codes',
            component: () => import('@/views/procedures/ProcedureCodes.vue'),
            meta: {                
                resource: 'ProcedureCode',
                pageTitle: 'Procedure Codes',
                navActiveLink: 'proc-codes',
                breadcrumb: [
                    {
                        text: 'Procedure Codes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/proc-codes/add-procedures-codes',
            name: 'proc-codes-add',
            component: () => import('@/views/procedures/AddProceduresCodes.vue'),
            meta: {
                resource: 'ProcedureCode',
                pageTitle: '',
                navActiveLink: 'proc-codes',
                breadcrumb: [
                    {
                        text: 'Procedure Codes',
                        active: false,
                        to: '/proc-codes',
                    },
                    {
                        text: 'Add Procedure Codes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/proc-codes/update-procedures-codes/:id',
            name: 'proc-codes-update',
            component: () => import('@/views/procedures/AddProceduresCodes.vue'),
            meta: {
                resource: 'ProcedureCode',
                pageTitle: '',
                navActiveLink: 'proc-codes',
                breadcrumb: [
                    {
                        text: 'Procedure Codes',
                        active: false,
                        to: '/proc-codes',
                    },
                    {
                        text: 'Update Procedure Codes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/proc-codes/update-procedures-codes/:id/settings',
            name: 'proc-codes-update-settings',
            component: () => import('@/views/procedures/AddProceduresCodes.vue'),
            meta: {
                resource: 'ProcedureCode',
                pageTitle: '',
                navActiveLink: 'proc-codes',
                breadcrumb: [
                    {
                        text: 'Procedure Codes',
                        active: false,
                        to: '/proc-codes',
                    },
                    {
                        text: 'Update Procedure Codes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/proc-bundles',
            name: 'proc-bundles',
            component: () => import('@/views/procedures/ProcedureBundles.vue'),
            meta: {
                resource: 'ProcedureBundle',
                pageTitle: 'Procedure Bundles',
                navActiveLink: 'proc-bundles',
                breadcrumb: [
                    {
                        text: 'Procedure Bundles',
                        active: true,
                    },
                ],
            },
        }
    ]

    return routes;
}
