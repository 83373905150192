import {Ability, AbilityBuilder} from '@casl/ability'

// Alternatively this data can be retrieved from server
export default function defineAbilitiesFor(user) {

    type Actions = 'create' | 'read' | 'update' | 'delete' | 'manage';
    type Subjects =
        'all'
        | 'Auth'
        | 'Scrubber'
        | 'Practice'
        | 'BillingRules'
        | 'Insurance'
        | 'ProcedureCode'
        | 'ProcedureType'
        | 'FeeSchedule'
        | 'ListOptions'
        | 'Users'
        | 'ClaimRules';
    type AppAbility = Ability<[Actions, Subjects]>;

    const {can, rules , cannot} = new AbilityBuilder<AppAbility>();

    console.log("triggerd abilities", user);

    if (!user) {
        can('read', 'Auth')
    } else {
        
        if (user.role === 'User') {

            can('read', ['Auth', 'Scrubber', 'Practice', 'Insurance', 'ProcedureCode', 'ProcedureType', 'FeeSchedule', 'ListOptions'])
            can('update' ,'Practice')
            can('create' ,'Practice')
            cannot('manage', 'ClaimRules')
            
        } else if (user.role === 'Admin') {
            can('manage', 'all')
        }
        
    }

    return new Ability(rules);
}