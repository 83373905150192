import {RouteConfig} from "vue-router/types/router";

export default function ConfigureRoutes(){
    const routes:RouteConfig[]=[
        {
            path: '/ins-companies',
            name: 'ins-companies',
            component: () => import('@/views/insurance/Insurance.vue'),
            meta: {
                resource: 'Insurance',
                pageTitle: 'Insurance Companies',
                breadcrumb: [
                    {
                        text: 'Insurance Companies',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/ins-companies/:id',
            name: 'ins-company-details',
            component: () => import('@/views/insurance/InsuranceCompanyDetails.vue'),
            meta: {
                resource: 'Insurance',
                pageTitle: '',
                navActiveLink: 'ins-companies',
                breadcrumb: [
                    {
                        text: 'Insurance Companies',
                        active: false,
                        to: '/ins-companies',
                    },
                    {
                        text: 'Detail',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/ins-companies/:id/eligibility-&-claims',
            name: 'ins-company-details-eligibility-&-claims',
            component: () => import('@/views/insurance/InsuranceCompanyDetails.vue'),
            meta: {
                resource: 'Insurance',
                pageTitle: '',
                navActiveLink: 'ins-companies',
                breadcrumb: [
                    {
                        text: 'Insurance Companies',
                        active: false,
                        to: '/ins-companies',
                    },
                    {
                        text: 'Detail',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/ins-companies/:id/authorizations',
            name: 'ins-company-details-authorizations',
            component: () => import('@/views/insurance/InsuranceCompanyDetails.vue'),
            meta: {
                resource: 'Insurance',
                pageTitle: '',
                navActiveLink: 'ins-companies',
                breadcrumb: [
                    {
                        text: 'Insurance Companies',
                        active: false,
                        to: '/ins-companies',
                    },
                    {
                        text: 'Detail',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/ins-companies/:id/mappings',
            name: 'ins-company-details-mappings',
            component: () => import('@/views/insurance/InsuranceCompanyDetails.vue'),
            meta: {
                resource: 'Insurance',
                pageTitle: '',
                navActiveLink: 'ins-companies',
                breadcrumb: [
                    {
                        text: 'Insurance Companies',
                        active: false,
                        to: '/ins-companies',
                    },
                    {
                        text: 'Detail',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/ins-plans',
            name: 'ins-plans',
            component: () => import('@/views/insurance/InsurancePlans.vue'),
            meta: {
                resource: 'Insurance',
                pageTitle: 'Insurance Plans',
                breadcrumb: [
                    {
                        text: 'Insurance Plans',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/ins-plans/:id',
            name: 'ins-plans-details',
            component: () => import('@/views/insurance/InsurancePlansDetails.vue'),
            meta: {
                resource: 'Insurance',
                pageTitle: '',
                navActiveLink: 'ins-plans',
                breadcrumb: [
                    {
                        text: 'Insurance Plans',
                        active: false,
                        to: '/ins-plans',
                    },
                    {
                        text: 'Detail',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/ins-plans/:id/eligibility-&-claims',
            name: 'ins-plans-details-eligibility-&-claims',
            component: () => import('@/views/insurance/InsurancePlansDetails.vue'),
            meta: {
                resource: 'Insurance',
                pageTitle: '',
                navActiveLink: 'ins-plans',
                breadcrumb: [
                    {
                        text: 'Insurance Plans',
                        active: false,
                        to: '/ins-plans',
                    },
                    {
                        text: 'Detail',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/ins-plans/:id/authorization',
            name: 'ins-plans-details-authorization',
            component: () => import('@/views/insurance/InsurancePlansDetails.vue'),
            meta: {
                resource: 'Insurance',
                pageTitle: '',
                navActiveLink: 'ins-plans',
                breadcrumb: [
                    {
                        text: 'Insurance Plans',
                        active: false,
                        to: '/ins-plans',
                    },
                    {
                        text: 'Detail',
                        active: true,
                    },
                ],
            },
        }
    ];
    return routes;
}
